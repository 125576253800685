<template>
  <router-view />
</template>

<style lang="scss">
// Remove padding and margin from the body
body {
  margin: 0;
  padding: 0;
}

#app {
  // font-family: "Times New Roman", Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
