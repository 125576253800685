<template>
  <div class="Unauthorized-Wrapper">
    <div class="UnauthorizedMessage-Container">
      <div class="ErrorCode-Text">401 - Unauthorized Access</div>
      <div class="ErrorMessage-Paragraph">
        Your access has been denied. The access token provided was rejected.
        Please contact your network administrator for further assistance.
      </div>
      <div class="ErrorMessage-Paragraph">
        If cookies are not enabled in your browser, please enable them and try
        again.
      </div>
      <div class="ErrorMessage-Paragraph">
        If you are still unable to connect, please reach out to our support team
        at <span id="SupportEmail">support@hyper-os.com.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnauthorizedPage",
};
</script>

<style lang="scss">
.Unauthorized-Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background: #d8f3ff;
}

.UnauthorizedMessage-Container {
  display: flex;
  flex-direction: column;

  width: 50%;
  height: 30%;

  padding: 15px;

  border-radius: 5px;
  border: 1px solid #000;
  background: #d9d9d9;
}

.ErrorMessage-Paragraph {
  padding-bottom: 20px;
}

.ErrorCode-Container {
  padding: 10px;
  font: 12px;
}

.ErrorCode-Text {
  padding-bottom: 10px;

  font-size: 24px;
  font-weight: bold;
  color: red;
}

#SupportEmail {
  font-weight: bold;
}
</style>
